! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	function initializeMenu() {
		// Mobile menu
		var $mobileMenuTrigger = $(".w-nav-button");
		var $mobileMenu = $(".mobile-navigation-menu");

		if ($mobileMenu.length == 0) {
			return;
		}

		$mobileMenu.mmenu({
			extensions: ["position-right"],
			navbar: {
				add: true,
				title: ""
			},
			screenReader: {
				aria: false,
				text: true
			}
		});

		var API = $mobileMenu.data("mmenu");

		$mobileMenuTrigger.click(function() {
			API.open();
			$mobileMenuTrigger.addClass("opened");

			return false;
		});

		$mobileMenu.data('mmenu').bind('closed', function() {
			$mobileMenuTrigger.removeClass("opened");
		});
	}

	initializeMenu();

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 1000 ) {
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".dropdown-wrap:has('.dropdown-container')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".dropdown-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Link with image
	$(".inside-page-wrapper a").has("img").css("box-shadow", "none");
	$(".inside-page-wrapper a").has("img").hover().css("background-color", "transparent");

	// Search toggling
	$("#search-toggle").click(function() {
		if ( $(".search-container-top").height() > 0 ) {
			$(".search-container-top").css("max-height", "0");
		}
		else {
			$(".search-container-top").css("max-height", "89px");
		}
	});

	$("#search-close").click(function() {
		$(".search-container-top").css("max-height", "0");
	});
});

$(window).scroll(function() {
	$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
});

$(document).on("select2:open", () => {
	document.querySelector(".select2-search__field").focus();
});
